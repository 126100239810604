import { LatLng } from 'leaflet';

import { Ride } from '../types/ride';
import { Marker } from '../types/marker';
import { Settings } from '../types/settings';
import { User } from '../types/user';
import { Credit } from '../types/credit';
import { Pin, Place, SelectedObject } from '../common/Map/interfaces';
import { Cluster } from '../types/cluster';

export interface RidesDestinationTime {
  [key: string]: number | null;
}

export interface DriverDestinationTime {
  [key: number]: number | null;
}

export interface DroppingRides {
  [key: string]: number | null;
}

export interface AsyncList<ListType> {
  isLoading: boolean;
  isFailedToGet: boolean;
  list: ListType[] | [];
}

export interface RidesReducerState {
  isLoading: boolean;
  isFailedToGet: boolean;
  ridesRequestTimestamp: number | null;
  allRides: Ride[];
  rides: Ride[];
  isLoadingBestRides: boolean;
  droppingRides: DroppingRides;
  ridesDestinationTime: RidesDestinationTime;
  driverDestinationTime: DriverDestinationTime;
  rideForm: {
    isLoading: boolean;
    error: string | null;
    success: boolean;
  };
}

export interface SettingsReducerState {
  isSettingsLoading: boolean;
  isFailedToGetSettings: boolean;
  isChanging: {
    begin_work: boolean;
    end_work: boolean;
    day_off: boolean;
    radius: boolean;
    min_dist: boolean;
    pin: boolean;
    ride_replacement: boolean;
    dropoff_state: boolean;
    pickup_state: boolean;
  };
  daySettings: Settings;
}

export interface MapReducerState {
  isLoading: boolean;
  radius: number | null;
  showTopTip: boolean;
  touchingMarkers: {
    [key: number]: number;
  };
  isCreatingMarker: boolean;
  newPin: Pin;
  selectedObject: SelectedObject;
  driverPin: Pin;
  resetPosition: number;
  markerForReplace: Marker | null;
  bestRides: AsyncList<Ride>;
  clusters: AsyncList<Cluster>;
  markers: AsyncList<Marker>;

  showLayers: boolean;
  layers: {
    bestRides: boolean;
    drivers: boolean;
    inactivePins: boolean;
    bigClusters: boolean;
  };
  routes: {
    [key: string]: LatLng[];
  };
  places: AsyncList<Place>;
}

export enum VerificationStep {
  verificationCode = 'verificationCode',
  licenseNumber = 'licenseNumber',
  phoneNumber = 'phoneNumber',
}

export type authHeaders = {
  'user-agent': string | null;
  authorization: string | null;
  [key: string]: string | null;
} | null;

export interface VerificationReducerState {
  isLoading: boolean;
  license_number: string | null;
  phone_number: string | null;
  verification_code: string | null;
  step: VerificationStep;
  error: string | null;
  authHeaders: {
    accessTokenLicense: authHeaders;
    accessTokenParams: authHeaders;
    accessTokenPhone: authHeaders;
    accountIdentifiers: authHeaders;
    phoneAuth: authHeaders;
    tcsConfig: authHeaders;
  };
  token_type: string | null;
  access_token: string | null;
  isLyftParamsLoading: boolean;
  isLyftParamsError: boolean;
}

export interface AuthReducerState {
  user: User | null;
}

export interface PurchaseProducts {
  [productId: string]: string;
}

export interface IosPurchase {
  receipt: string;
}

export const isIosPurchase = (purchase: any): purchase is IosPurchase => {
  return purchase.receipt !== undefined;
};

export interface AndroidPurchase {
  purchase_token: string;
  product_id: string;
}

export const isAndroidPurchase = (purchase: any): purchase is AndroidPurchase => {
  return purchase.purchase_token !== undefined && purchase.product_id !== undefined;
};

export type Purchase = AndroidPurchase | IosPurchase;

export type WizardStep =
  | null
  | 'intro'
  | 'beginningOfWork'
  | 'workingTime'
  | 'workingDays'
  | 'minimumRidePayout'
  | 'maxMilesToPickUp'
  | 'pickUpOutsideState'
  | 'dropOffOutsideState'
  | 'allDone';

export enum SearchErrorStatusCodes {
  none = 0,
  expiredToken = -3,
  notApproved = -4,
  blockedInLyft = -5,
  updateSession = -6,
}

export interface AppReducerState {
  headerTitle: string;
  isSearchOn: boolean | null;
  isAgreed: boolean | null;
  isSearchLoading: boolean;
  isSearchLoadingInBackground: boolean;
  isFailedToGetSearch: boolean;
  isHelpScreen: boolean;
  showSidebar: boolean;
  rideCreditsAlert: null | 'settings' | 'search';
  rideCredits: number | null;
  searchErrorStatus: number | null;
  products: PurchaseProducts;
  isRideCreditsLoading: boolean;
  theme: boolean;
  showRideShare: boolean;
  showAllRides: boolean;
  wizardStep: WizardStep;
  isReviewApp: boolean;
  showUpdatePage: boolean;
}

export type SettingsRecommendations = {
  search: boolean;
  autoAdjustSearchPins: boolean;
  minimumRidePayout: boolean;
  maxMilesToPickup: boolean;
  locationPermission: boolean;
  pushPermission: boolean;
  intro: boolean;
  searchSuccess: boolean;
  noCredits: boolean;
  autoAdjustSearchPinsSuccess: boolean;
  minimumRidePayoutSuccess: boolean;
  maxMilesToPickupSuccess: boolean;
  allDone: boolean;
};

export enum PermissionStatus {
  Undefined = -1,
  NotAllowed,
  OnlyWhenUsing,
  Always,
}

export type SettingsRecommendationsReducerState = {
  recommendations: SettingsRecommendations;
  permissions: {
    notification: PermissionStatus;
    location: PermissionStatus;
    battery: PermissionStatus;
    gps: PermissionStatus;
  };
};

export interface CreditsHistoryReducerState {
  isLoading: boolean;
  isFailedToGet: boolean;
  credits: Credit[];
}

export interface AppState {
  app: AppReducerState;
  auth: AuthReducerState;
  userForms: {
    verification: VerificationReducerState;
  };
  settings: SettingsReducerState;
  rides: RidesReducerState;
  creditsHistory: CreditsHistoryReducerState;
  settingsRecommendations: SettingsRecommendationsReducerState;
  map: MapReducerState;
}
