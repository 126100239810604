import styled from 'styled-components';
import React from 'react';

import { useTranslation } from 'helpers/hooks';

const CreditsInfoContainer = styled.div`
  font-size: 16px;
  letter-spacing: -0.4px;

  @media (max-width: 375px) {
    font-size: 15px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 4px;
    display: block;
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

const credits = [
  ['1', '$10', '$20'],
  ['2', '$20', '$30'],
  ['3', '$30', '$40'],
  ['4', '$40', '$50'],
  ['5', '$50', '$100'],
  ['6', '$100', '$150'],
  ['7', '$150', '$200'],
  ['8', '$200', '$250'],
  ['9', '$250', '$300'],
  ['10', '$300', ''],
];

export const CreditsInfo = React.memo(function () {
  const { t } = useTranslation();

  return (
    <CreditsInfoContainer>
      <ul>
        {credits.map(([rideCredit, min, max]) => {
          let locale = '';

          switch (rideCredit) {
            case '1':
              locale = 'newCredits.price.rideCredit';
              break;
            case '10':
              locale = 'newCredits.price.moreRideCredits';
              break;
            default:
              locale = 'newCredits.price.rideCredits';
          }

          return (
            <li
              key={rideCredit}
              dangerouslySetInnerHTML={{
                __html: t(locale, {
                  rideCredit,
                  min,
                  max,
                }),
              }}
            />
          );
        })}
      </ul>
    </CreditsInfoContainer>
  );
});
