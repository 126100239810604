import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { Events, eventsService, ShowClientMessageEvent } from '../../services/EventsService';
import Modal from '../../common/Modal';
import { logout } from '../../store/user/actions';
import storageService, { StorageKeys } from '../../services/StorageService';
import { appDep } from '../../config/settings';
import { SearchErrorStatusCodes } from '../../store/interfaces';

export const checkIsBlockedByLyft = (searchErrorStatus: number | null) => {
  return searchErrorStatus !== null && searchErrorStatus !== SearchErrorStatusCodes.none;
};

const ClientMessageModal: React.FC = function () {
  const [clientMessage, setClientMessage] = useState<{
    message: string;
    errorCode: number;
  } | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClientMessage = (e: ShowClientMessageEvent) => {
      const { message, errorCode } = e.detail;
      const isAllowedToShow = errorCode in SearchErrorStatusCodes;

      if (!isAllowedToShow) {
        return;
      }

      if (errorCode === SearchErrorStatusCodes.blockedInLyft) {
        return;
      }

      if (
        errorCode === SearchErrorStatusCodes.notApproved &&
        storageService.getItem(StorageKeys.showNotAllowedSearchModal)
      ) {
        return;
      }

      setClientMessage({
        message,
        errorCode,
      });
    };

    eventsService.on(Events.ShowClientMessage, handleClientMessage);

    return () => {
      eventsService.off(Events.ShowClientMessage, handleClientMessage);
    };
  }, []);

  if (!clientMessage) {
    return null;
  }

  let title;
  let text: any = clientMessage.message;
  let applyText = '';
  let closeOnMask = true;
  let handleClose = () => setClientMessage(null);

  switch (clientMessage.errorCode) {
    case SearchErrorStatusCodes.notApproved: {
      title = t('search.notAllowedModal.title');
      text = t('search.notAllowedModal.body', { appDep });
      applyText = t('search.notAllowedModal.buttonOk');
      handleClose = () => {
        storageService.setItem(StorageKeys.showNotAllowedSearchModal, '1');
        setClientMessage(null);
      };
      break;
    }
    case SearchErrorStatusCodes.updateSession:
    case SearchErrorStatusCodes.expiredToken: {
      text = t('search.expiredTokenModal.body', { appDep });
      applyText = t('search.expiredTokenModal.buttonOk');
      closeOnMask = false;
      handleClose = () => {
        setClientMessage(null);
        storageService.setItem(StorageKeys.showRegistrationTip, '1');
        dispatch(logout());
      };
      break;
    }
    default:
      return null;
  }

  return (
    <Modal
      title={title}
      applyText={applyText}
      showCancel={false}
      closeOnMask={closeOnMask}
      onClose={handleClose}
      onApply={handleClose}
    >
      {text}
    </Modal>
  );
};

export default ClientMessageModal;
